@import url("https://fonts.googleapis.com/css?family=Montserrat");

html,
body {
  font-family: "Montserrat", sans-serif;
}

.progress-bar-labels {
  margin-top: 50px;
  /* width: 1000px; */
  width: 85%;
  font-size: 20px;
  font-weight: bold;
}

.dem-label {
  float: left;
  width: 50%;
  color: blue;
}

.rep-label {
  float: left;
  width: 50%;
  text-align: right;
  color: red;
}

.num-label {
  font-size: 40px;
  font-weight: bold;
}

.progress-bar {
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  height: 30px;
  /* width: 1000px; */
  width: 70%;
  background: #d3d3d3;
  border-radius: 50px;
  border: 1px solid #333;
  display: inline-block;
  overflow: hidden;
}

.blank-filler {
  float: left;
  background: #d3d3d3;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
  color: black;
  font-weight: bold;
  padding-left: -1px;
  padding-right: -1px;
}

.dem-filler {
  float: left;
  background: blue;
  height: inherit;
  min-width: 0px;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}

.rep-filler {
  float: left;
  background: red;
  height: 100%;
  min-width: 0px;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}

.marker {
  /* margin-left: 48.5%; */
  margin-left: 17%;
}
