.body {
  min-width: 900px; width: 100%;
  /* margin-left: 15%;
  margin-right: 10%; */
  display: inline-block;
  text-align: center;
}

.content {
  /* margin-left: 15%; */
  display: inline-block; 
  margin-left: auto;    
  margin-right: auto;
  justify-content: center;
}


.jumbotron {
  display: inline-block;   
  justify-content: center;
  width: 100%;
}

h1 {
  font-size: 450%;
  font-weight: bold;
}

.page-header {
  font-size: 150%;
}

.winner-text {
  font-size: 200%;
  margin-left: 18%;
  margin-right: 0%;
}

path {
  pointer-events: all;
}
path:hover {
  opacity: 0.5;
  cursor: pointer;
}

button {
  margin: 5px;
}

.results {
  margin-left: 10px;
}

.toggle-btns {
  /* width: 1000px; */
  width: 70%;
  margin-top: 40px;
  margin-bottom: 80px;
  overflow: hidden;

  display: inline-block;   
}

.dem-btn {
  float: left;
  background-color: blue;
  font-weight: bold;
}

.dem-btn:hover {
  background-color: blue;
  opacity: 0.75;
  cursor: pointer;
}

.rep-btn {
  float: right;
  background-color: red;
  font-weight: bold;
}

.rep-btn:hover {
  background-color: red;
  opacity: 0.75;
  cursor: pointer;
}

.dropdown {
  display: inline-block;
  margin-top: 0%;
  margin-left: 500px;
  /* margin-right: 25%; */
}

.dropdown select {
  margin-left: 20%;
}
