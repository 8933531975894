@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body {
  min-width: 900px; width: 100%;
  /* margin-left: 15%;
  margin-right: 10%; */
  display: inline-block;
  text-align: center;
}

.content {
  /* margin-left: 15%; */
  display: inline-block; 
  margin-left: auto;    
  margin-right: auto;
  justify-content: center;
}


.jumbotron {
  display: inline-block;   
  justify-content: center;
  width: 100%;
}

h1 {
  font-size: 450%;
  font-weight: bold;
}

.page-header {
  font-size: 150%;
}

.winner-text {
  font-size: 200%;
  margin-left: 18%;
  margin-right: 0%;
}

path {
  pointer-events: all;
}
path:hover {
  opacity: 0.5;
  cursor: pointer;
}

button {
  margin: 5px;
}

.results {
  margin-left: 10px;
}

.toggle-btns {
  /* width: 1000px; */
  width: 70%;
  margin-top: 40px;
  margin-bottom: 80px;
  overflow: hidden;

  display: inline-block;   
}

.dem-btn {
  float: left;
  background-color: blue;
  font-weight: bold;
}

.dem-btn:hover {
  background-color: blue;
  opacity: 0.75;
  cursor: pointer;
}

.rep-btn {
  float: right;
  background-color: red;
  font-weight: bold;
}

.rep-btn:hover {
  background-color: red;
  opacity: 0.75;
  cursor: pointer;
}

.dropdown {
  display: inline-block;
  margin-top: 0%;
  margin-left: 500px;
  /* margin-right: 25%; */
}

.dropdown select {
  margin-left: 20%;
}

html,
body {
  font-family: "Montserrat", sans-serif;
}

.progress-bar-labels {
  margin-top: 50px;
  /* width: 1000px; */
  width: 85%;
  font-size: 20px;
  font-weight: bold;
}

.dem-label {
  float: left;
  width: 50%;
  color: blue;
}

.rep-label {
  float: left;
  width: 50%;
  text-align: right;
  color: red;
}

.num-label {
  font-size: 40px;
  font-weight: bold;
}

.progress-bar {
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  height: 30px;
  /* width: 1000px; */
  width: 70%;
  background: #d3d3d3;
  border-radius: 50px;
  border: 1px solid #333;
  display: inline-block;
  overflow: hidden;
}

.blank-filler {
  float: left;
  background: #d3d3d3;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
  color: black;
  font-weight: bold;
  padding-left: -1px;
  padding-right: -1px;
}

.dem-filler {
  float: left;
  background: blue;
  height: inherit;
  min-width: 0px;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}

.rep-filler {
  float: left;
  background: red;
  height: 100%;
  min-width: 0px;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}

.marker {
  /* margin-left: 48.5%; */
  margin-left: 17%;
}

.container {
  display: inline-block;
  margin-top: 0;
  width: inherit;
  margin-left: 750px;
  margin-right: 0;
  white-space: nowrap;
}

.btn-group {
  float: left;
  margin-right: 20%;
}

.btn-group button {
  height: 30px;
  width: 30px;

  background-color: #d3d3d3; /* Green background */
  border: 1px solid black; /* Green border */
  color: black; /* White text */
  font-weight: bold;
  padding: 2px 2px;
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
}

.state {
  width: 60px !important;
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */
.btn-group button:hover {
  opacity: 0.5;
  cursor: pointer;
}

.red {
  background-color: red !important;
}

.blue {
  background-color: blue !important;
}

